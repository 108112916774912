:root {
  font-size: 18px;
  --light-blue: #7dd5f7;
  --dark-blue: #2b3986;
}

* {
  box-sizing: border-box;
}

.content, .content-area {
  max-width: 960px;
  margin: 0 auto;
}

.content {
  padding: 0 1rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.5em;
}
body {
  color: #333;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--dark-blue);
}

img, video {
  max-width: 100%;
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px; width: 1px;
  overflow: hidden;
  position: absolute !important;
}

.contain {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.title {
  height: 80px;
  padding: 16px;
  margin: 0;
}

.logo {
  max-height: 100%;
  max-width: 100%;
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-dark-blue,
.bg-dark-blue a {
  background-color: var(--dark-blue);
  color: #fff;
}

.nav-links {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-links a {
  display: block;
  height: 100%;
  padding: 16px;
  margin: 0;
  background-color: #0000;
  text-decoration-color: transparent;
  transition: background-color 200ms ease-out, text-decoration-color 200ms ease-out;
}

.nav-links a:hover {
  background-color: #fff;
  background-color: #0003;
  transition: background-color 75ms ease-out, text-decoration-color 75ms ease-out;
  text-decoration-color: currentColor;
}

.min-full-height {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.block {
  display: block;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.space-around {
  justify-content: space-around;
}

.hero-links {
  padding: 0 .5rem 1rem;
  gap: 1rem
}

@media (min-width: 768px) {
  .hero-links {
    padding: 0 2rem 1rem 2rem;
    gap: 2rem;
  }
}

.p1 {
  padding: 1rem;
}
.pv1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.hidden {
  display: none;
}


.home-bg:before {
  content: "";
  display: block;
  inset: 0;
  position: absolute;
  background-image: url(./home-bg.jpg);
  background-size: cover;
  background-position: center;
  opacity: 0.10;
  z-index: -1;
}

.w100 {
  width: 100%;
}
.align-center {
  align-items: center;
}

.shape-link {
  display: block;
  aspect-ratio: 1 / 1;
  width: 100%;
}

.home-shape {
  display: block;
  border-radius: 50%;
  border-bottom-left-radius: 0;
  background-color: var(--light-blue);
  padding: .25rem;
  transition: padding 200ms ease-out;
  height: 100%;
}

.home-shape:hover {
  transition: padding 75ms ease-out;
  padding: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .home-shape {
    padding: 1rem;
  }
  .home-shape:hover {
    padding: .5rem;
  }
}

.home-shape-img {
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.home-shape-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-grid {
  display: grid;
  grid-template-columns: [grid-start] repeat(auto-fit, minmax(300px, 1fr)) [grid-end];
  grid-template-rows: auto auto [content-start] auto [content-end] auto;
}

.photo-grid .content {
  grid-column-start: grid-start;
  grid-column-end: grid-end;
  grid-row-start: content-start;
  grid-row-end: content-end;
}

.img {
  height: 200px;
  width: 100%;
  background-color: gray;
  transition: transform 200ms ease-out;
  overflow: hidden;
}

.img:hover {
  transform: scale(1.05);
  z-index: 1;
  transition: transform 75ms ease-out;
}

.about-float-image {
  float: right;
  width: 300px;
  max-width: 50%;
  margin: 0 0 1rem 1rem;
}

.video-thumbnails {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.video-thumb {
  position: relative;
  aspect-ratio: 16 / 9;
  padding:0;
  overflow: hidden;
  border-radius: 0px;
  border: none;
}

.video-thumb:after {
  content: "";
  display: block;
  inset: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms ease-out;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: .5;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.333), inset 0 0 10px rgba(0, 0, 0, 0.667);
  /* play button svg */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 30,20 80,50 30,80 z' fill='%23fff'/%3E%3C/svg%3E");
}

.video-thumb:hover:after {
  opacity: 1;
  transition: opacity 75ms ease-out;
}

.video-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  line-height: 0;
}

.video-container video {
  width: 100%;
  height: 100%;
}