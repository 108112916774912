:root {
  --light-blue: #7dd5f7;
  --dark-blue: #2b3986;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

.content, .content-area {
  max-width: 960px;
  margin: 0 auto;
}

.content {
  padding: 0 1rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.5em;
}

body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: var(--dark-blue);
}

img, video {
  max-width: 100%;
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.contain {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  height: 80px;
  margin: 0;
  padding: 16px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-dark-blue, .bg-dark-blue a {
  background-color: var(--dark-blue);
  color: #fff;
}

.nav-links {
  align-items: center;
  margin: 0;
  display: flex;
}

.nav-links a {
  background-color: #0000;
  height: 100%;
  margin: 0;
  padding: 16px;
  text-decoration-color: #0000;
  transition: background-color .2s ease-out, text-decoration-color .2s ease-out;
  display: block;
}

.nav-links a:hover {
  background-color: #0003;
  text-decoration-color: currentColor;
  transition: background-color 75ms ease-out, text-decoration-color 75ms ease-out;
}

.min-full-height {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.block {
  display: block;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.space-around {
  justify-content: space-around;
}

.hero-links {
  gap: 1rem;
  padding: 0 .5rem 1rem;
}

@media (width >= 768px) {
  .hero-links {
    gap: 2rem;
    padding: 0 2rem 1rem;
  }
}

.p1 {
  padding: 1rem;
}

.pv1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.hidden {
  display: none;
}

.home-bg:before {
  content: "";
  opacity: .1;
  z-index: -1;
  background-image: url("home-bg.6d6d2377.jpg");
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  inset: 0;
}

.w100 {
  width: 100%;
}

.align-center {
  align-items: center;
}

.shape-link {
  aspect-ratio: 1;
  width: 100%;
  display: block;
}

.home-shape {
  background-color: var(--light-blue);
  border-radius: 50% 50% 50% 0;
  height: 100%;
  padding: .25rem;
  transition: padding .2s ease-out;
  display: block;
}

.home-shape:hover {
  width: 100%;
  padding: 0;
  transition: padding 75ms ease-out;
}

@media (width >= 768px) {
  .home-shape {
    padding: 1rem;
  }

  .home-shape:hover {
    padding: .5rem;
  }
}

.home-shape-img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-shape-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photo-grid {
  grid-template-rows: auto auto[content-start] auto[content-end] auto;
  grid-template-columns: [grid-start] repeat(auto-fit, minmax(300px, 1fr))[grid-end];
  display: grid;
}

.photo-grid .content {
  grid-area: content-start / grid-start / content-end / grid-end;
}

.img {
  background-color: gray;
  width: 100%;
  height: 200px;
  transition: transform .2s ease-out;
  overflow: hidden;
}

.img:hover {
  z-index: 1;
  transition: transform 75ms ease-out;
  transform: scale(1.05);
}

.about-float-image {
  float: right;
  width: 300px;
  max-width: 50%;
  margin: 0 0 1rem 1rem;
}

.video-thumbnails {
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  display: grid;
}

.video-thumb {
  aspect-ratio: 16 / 9;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.video-thumb:after {
  content: "";
  cursor: pointer;
  opacity: .5;
  background-color: #00000080;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 30,20 80,50 30,80 z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity .2s ease-out;
  display: block;
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 30px #0005, inset 0 0 10px #000a;
}

.video-thumb:hover:after {
  opacity: 1;
  transition: opacity 75ms ease-out;
}

.video-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  line-height: 0;
}

.video-container video {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.68ee6406.css.map */
